<template>
  <div class="setting-privilege" v-if="!isProcessing">
    <p class="setting-privilege__description">
      入会特典に記載する「タイトル」「説明文」「画像（複数枚可）」を変更できます。
    </p>
    <div class="setting-privilege__box" v-for="(privilege, index) in privileges" :key="privilege.param.rank">
      <p class="setting-privilege__box__number">入会特典 {{ encircledNumber(privilege.param.rank) }}</p>
      <v-btn class="setting-privilege__box__btn" depressed @click="$refs.confirm[index].open()">
        <v-icon class="setting-privilege__box__btn__icon">delete</v-icon>
      </v-btn>
      <!-- タイトル -->
      <label class="setting-privilege__box__label">タイトル<span class="setting-privilege__box__label__required">*</span></label>
      <input class="setting-privilege__box__title" placeholder="タイトルを入力する" v-model="privilege.param.title" type="text" />
      <div class="setting-privilege__box__count">
        <span :class="{'setting-privilege__box__count__over' : privilege.param.title.length > TITLE_MAX_LENGTH }">{{ privilege.param.title.length }}/{{ TITLE_MAX_LENGTH }}</span>
      </div>
      <!-- 説明文 -->
      <label class="setting-privilege__box__label">説明文<span class="setting-privilege__box__label__required">*</span></label>
      <textarea class="setting-privilege__box__textarea" placeholder="説明文を入力する" v-model="privilege.param.description" type="text" />
      <div class="setting-privilege__box__count">
        <span :class="{'setting-privilege__box__count__over' : privilege.param.description.length > DESCRIPTION_MAX_LENGTH }">{{ privilege.param.description.length }}/{{ DESCRIPTION_MAX_LENGTH }}</span>
      </div>
      <!-- イメージ画像 -->
      <label class="setting-privilege__box__label">画像（複数枚可）</label>
      <!-- 画像（横スクロール） -->
      <div class="setting-privilege__box__multi">
        <label class="setting-privilege__box__multi__upload">
          <v-icon class="setting-privilege__box__multi__upload__icon">camera_alt</v-icon>
          <input class="setting-privilege__box__multi__upload__input" type="file" ref="images" accept=".png, .jpg, .jpeg, .gif" multiple @change="selectPicture(privilege, $event)" />
        </label>
        <!-- アップロード済みの画像表示 -->
        <div class="setting-privilege__box__multi__cover" v-for="(url, imageIndex) in privilege.param.images" :key="url">
          <v-img class="setting-privilege__box__multi__cover__img" :src="url" />
          <v-btn class="setting-privilege__box__multi__cover__btn" depressed @click="deleteImage(privilege, false, imageIndex)">
            <v-icon class="setting-privilege__box__multi__cover__btn__icon">close</v-icon>
          </v-btn>
        </div>
        <!-- この画面で追加した画像表示 -->
        <div class="setting-privilege__box__multi__cover" v-for="(url, imageIndex) in privilege.urls" :key="url">
          <v-img class="setting-privilege__box__multi__cover__img" :src="url" />
          <v-btn class="setting-privilege__box__multi__cover__btn" depressed @click="deleteImage(privilege, true, imageIndex)">
            <v-icon class="setting-privilege__box__multi__cover__btn__icon">close</v-icon>
          </v-btn>
        </div>
      </div>
      <confirm-dialog ref="confirm" title="入会特典の削除" :msg="'入会特典' + encircledNumber(privilege.param.rank) + 'を削除します。'" @do-action="deletePrivilege(index)" />
    </div>
    <div class="setting-privilege__add">
      <v-btn text class="setting-privilege__add__btn" @click="addPrivilege()">新しく追加する</v-btn>
    </div>
    <v-btn class="setting-privilege__btn" :disabled="!isFilled" depressed @click="editPrivilege()">変更する</v-btn>
  </div>
</template>

<script>
import image from '@/assets/lib/image'
import string from '@/assets/lib/string'
import ConfirmDialog from '@/components/common/confirm'

export default {
  name: 'setting_privilege',
  mixins: [image, string],
  components: { ConfirmDialog },
  data () {
    return {
      // 更新する特典情報の一覧
      // param：firestoreを更新するための値、urls：画像表示用のURL、blobs：画像アップロード用Blob、paths：画像アップロード先のパス
      // [ { param: {}, urls: [], blobs: [], paths: [] }, {}, ... ]
      privileges: [],
      // 削除する特典情報一覧
      deletePrivileges: [],
      // 削除する画像のURL一覧
      deleteImageURLs: []
    }
  },
  async mounted () {
    // 入会特典情報を取得
    if (this.settingPrivileges.length === 0 ) await this.$store.dispatch('settingPrivileges/getSettingPrivileges')

    this.privileges = this.settingPrivileges.map(settingPrivilege => {
      // settingPrivilegeを直接代入すると浅いコピーになりstoreの値まで変更されるのでObject.assignを使う
      const param = Object.assign({}, settingPrivilege)
      return Object.assign({}, { param: param, urls: [], blobs: [], paths: [] })
    })

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Object[]} 入会特典情報一覧
     */
    settingPrivileges () {
      return this.$store.getters['settingPrivileges/settingPrivileges']
    },
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {Number} タイトルの文字数上限（固定）
     */
    TITLE_MAX_LENGTH () {
      return 30
    },
    /**
     * @return {Number} 説明の文字数上限（固定）
     */
    DESCRIPTION_MAX_LENGTH () {
      return 200
    },
    /**
     * @return {Boolean} 全ての必須項目が入力されているかどうか
     */
    isFilled () {
      const isFilled = this.privileges.every(privilege => {
        return privilege.param.title.trim().length > 0 && privilege.param.title.trim().length <= this.TITLE_MAX_LENGTH &&
        privilege.param.description.trim().length > 0 && privilege.param.description.trim().length <= this.DESCRIPTION_MAX_LENGTH
      })
      return isFilled
    }
  },
  methods: {
    /**
     * 画像の選択
     * @param {Object} privilege 特典情報
     * @param {Object} event 発火したイベント
     */
    async selectPicture (privilege, event) {
      event.preventDefault()

      // 保存先URLパスとファイルの格納
      const files = event.target.files
      const path = '/settings/'

      await Array.from(files).forEach(file => {
        this.doImgProcess(file, path).then(results => {
          if (!results) return
          privilege.urls.push(results.url)
          privilege.blobs.push(results.blob)
          privilege.paths.push(results.path)
        })
      })
    },
    /**
     * 画像の削除
     * @param {Object} privilege 画像を削除する特典情報
     * @param {Boolean} isNew 新規追加画像かどうか
     * @param {Number} index 画像の順番
     */
    deleteImage (privilege, isNew, index) {
      if (isNew) {
        // 新規追加画像であればurls,blobs,pathsの該当箇所を削除
        privilege.urls.splice(index, 1)
        privilege.blobs.splice(index, 1)
        privilege.paths.splice(index, 1)
      } else {
        // 既存画像であれば画像削除urlに追加し、param.imagesの該当箇所を削除
        this.deleteImageURLs.push(privilege.param.images[index])
        privilege.param.images.splice(index, 1)
      }
    },
    /**
     * 特典の追加
     */
    addPrivilege () {
      const param = {
        title: '',
        description: '',
        rank: this.privileges.length + 1,
        images: []
      }
      this.privileges.push({ param: param, urls: [], blobs: [], paths: [] })
    },
    /**
     * 特典の削除
     * @param {Number} privilegeIndex 削除する特典の番号
     */
    deletePrivilege (privilegeIndex) {
      const privilege = this.privileges[privilegeIndex].param
      // 既に登録済みの特典情報の場合、DBのデータ削除用配列に格納する
      if (privilege.spid) {
        this.deletePrivileges.push(privilege.spid)
        Array.prototype.push.apply(this.deleteImageURLs, privilege.images)
      }
      this.privileges.splice(privilegeIndex, 1)

      // ランクのズレを修正
      this.privileges.forEach((privilege, index) => {
        privilege.param.rank = index + 1
      })
    },
    /**
     * 特典情報の更新
     */
    async editPrivilege () {
      this.$store.commit('setSubmitting', true)

      // 画像の処理
      const imagePromises = []
      this.privileges.forEach(privilege => {
        imagePromises.push(this.uploadImages(privilege))
      })

      const urls = await Promise.all(imagePromises)

      // 画像の削除
      this.deleteImages()

      // 特典情報の更新、追加処理
      const privilegePromises = []
      this.privileges.forEach((privilege, index) => {
        const params = privilege.param

        params.updatedAt = new Date()
        if (urls[index].length > 0) Array.prototype.push.apply(params.images, urls[index])

        if (!privilege.param.spid) {
          // 新規追加の場合
          params.createdAt = new Date()
          privilegePromises.push(this.$store.dispatch('settingPrivileges/addSettingPrivilege', params))
        } else {
          // 更新の場合
          privilegePromises.push(this.$store.dispatch('settingPrivileges/updateSettingPrivilege', { spid: privilege.param.spid, params: params }))
        }
      })

      // 特典情報の削除
      this.deletePrivileges.forEach(spid => {
        privilegePromises.push(this.$store.dispatch('settingPrivileges/deleteSettingPrivilege', spid))
      })

      await Promise.all(privilegePromises)

      // ホームへ遷移
      this.$store.commit('setTelop', { show: true, msg: '特典情報を更新しました。', type: 'success' })
      this.$router.push({ name: 'home' })
    },
    /**
     * 画像のアップロード
     * @param {Number} privilege 特典情報
     * @return {String[]} 画像のURL 画像の変更がない場合は空配列
     */
    async uploadImages (privilege) {
      // 画像の変更がない場合は終了する
      if (privilege.blobs.length === 0) return []
      const promises = []

      privilege.blobs.forEach((blob, index)=> {
        promises.push(this.$store.dispatch('cloudstorage/uploadImage', { blob: blob, path: privilege.paths[index] }))
      })

      return await Promise.all(promises)
    },
    /**
     * storageの画像の削除
     */
    deleteImages () {
      this.deleteImageURLs.forEach(url => {
        this.$store.dispatch('cloudstorage/deleteImage', url)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/size.scss";
@import "@/assets/sass/color.scss";
@import "@/assets/sass/fontfamily.scss";

.setting-privilege {
  width: 100vw;
  max-width: $max_width;
  min-height: calc(100vh - #{$header_height});
  padding: $padding_height $padding_width;
  margin: $header_height auto 0;
  &__description {
    margin: 0;
    font-size: 1.2rem;
  }
  &__box {
    position: relative;
    box-sizing: border-box;
    padding: 10px;
    margin-top: 30px;
    border: 2px solid $gray_lighten_color;
    &__number {
      margin: 0;
      font-size: 1.2rem;
      font-weight: bold;
      color: $orange_color;
    }
    &__btn {
      position: absolute;
      top: 5px;
      right: 10px;
      &.v-btn:not(.v-btn--round).v-size--default {
        width: 20px;
        min-width: 0;
        height: 20px;
        padding: 0;
      }
      &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: $white_color;
      }
      &__icon {
        font-family: $material-outlined;
        &.v-icon {
          font-size: 2rem;
          color: $black_lighten_color;
        }
      }
    }
    &__label {
      display: block;
      margin-top: 10px;
      font-size: 1.2rem;
      font-weight: bold;
      line-height: 1.5rem;
      &__required {
        color: $red_color;
      }
    }
    &__title {
      width: 100%;
      max-width: $max_width;
      height: 30px;
      padding: 5px 0;
      font-size: 1.6rem;
      line-height: 2rem;
      border-bottom: 1px solid $black_color;
      outline: 0;
      &::placeholder {
        color: $gray_color;
      }
    }
    &__count {
      margin-top: 5px;
      font-size: 1rem;
      line-height: 1.2rem;
      text-align: right;
      &__over {
        color: $red_color;
      }
    }
    &__input {
      width: 100%;
      padding: 5px 0;
      font-size: 1.6rem;
      line-height: 1.9rem;
      border-bottom: 1px solid $gray_color;
      outline: none;
    }
    &__textarea {
      display: block;
      width: 100%;
      height: 75px;
      padding: 5px 0;
      overflow: hidden;
      font-size: 1.6rem;
      line-height: 2rem;
      resize: none;
      border-bottom: 1px solid $black_color;
      outline: none;
      &::placeholder {
        color: $gray_color;
      }
    }
    &__multi {
      display: flex;
      width: 100%;
      height: 50px;
      margin-top: 5px;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      &__upload {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 50px;
        min-height: 50px;
        background-color: $gray_lighten_color;
        outline: none;
        &__icon {
          font-family: $material-outlined;
          object-fit: cover;
          &.v-icon {
            font-size: 2rem;
            color: $gray_color;
          }
        }
        &__input {
          display: none;
        }
      }
      &__cover {
        position: relative;
        display: inline-block;
        width: calc(50px / 0.486);
        max-width: calc(50px / 0.486);
        height: 50px;
        max-height: 50px;
        margin-left: 10px;
        &__img {
          width: 100%;
          max-width: 100%;
          height: 100%;
          max-height: 100%;
        }
        &__btn {
          position: absolute;
          top: 5px;
          right: 5px;
          &.v-btn:not(.v-btn--round).v-size--default {
            width: 10px;
            min-width: 0;
            height: 10px;
            padding: 0;
          }
          &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
            background-color: transparent;
          }
          &__icon {
            font-family: $material-outlined;
            &.v-icon {
              font-size: 1rem;
              color: $gray_lighten_color;
            }
          }
        }
      }
    }
  }
  &__add {
    text-align: right;
    &__btn {
      margin: 20px 0 0;
      font-size: 1.2rem;
      font-weight: bold;
      color: $orange_color;
      &.v-btn:not(.v-btn--round).v-size--default {
        min-width: auto;
        height: auto;
        padding: 0;
      }
    }
  }
  &__btn {
    display: block;
    margin: 50px auto 0 auto;
    font-size: 1.2rem;
    color: $white_color;
    border-radius: 10px;
    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: auto;
      height: auto;
      padding: 10px 15px;
    }
    &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: $orange_color;
    }
    &.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      color: $white_color !important;
      background-color: $orange_lighten_color !important;
    }
  }
}
</style>
